import { GetStaticProps } from 'next';
import Head from 'next/head';
import * as React from 'react';
import { useState } from 'react';

import { Newsletter } from '../components/Newsletter/lazy';
import { LastPost, VerticalPost } from '../components/Post/lazy';
import { Sidebar } from '../components/Sidebar/lazy';
import { getSortedPostsData } from '../lib/posts';
import { PostEntity } from '../types';
import { titleBuilder } from '../utils/titleBuilder';

const App = ({ posts }: { posts: PostEntity[] }): JSX.Element => {
    const [amountToShow, setAmountToShow] = useState(8);
    const [first, ...rest] = posts;
    return (
        <>
            <Head>
                <title>{titleBuilder()}</title>
            </Head>

            <div
                className={'bg-gradient-to-b to-blue-lightest from-white relative hidden lg:block'}>
                <section className={'page-container max-w-screen-xl'}>
                    <LastPost {...first} />
                </section>
            </div>
            <div
                className={
                    'py-6 lg:py-16 relative lg:bg-blue-lightest lg:bg-none  bg-gradient-to-b to-blue-lightest from-white'
                }>
                <section className={'page-container max-w-screen-xl'}>
                    <ul className={'videos-list'}>
                        {[first, ...rest].slice(0, 4).map((post) => (
                            <VerticalPost {...post} key={post.id} />
                        ))}
                    </ul>
                </section>
            </div>
            <div className={'newsletter-wrapper homepage-newsletter'}>
                <Newsletter />
            </div>
            <div className={'page-main'}>
                <section className={'page-container lg:grid max-w-screen-xl'}>
                    <div>
                        <ul className={'grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-14'}>
                            {rest
                                .slice(3)
                                .map(
                                    (post, index) =>
                                        index + 1 <= amountToShow && (
                                            <VerticalPost {...post} key={post.id} />
                                        )
                                )}
                        </ul>
                        <div className={'w-full text-center my-8'}>
                            {rest.length > amountToShow && (
                                <button
                                    className={'pill-button'}
                                    onClick={() => setAmountToShow((prev) => prev * 2)}>
                                    Carregar Mais Posts
                                </button>
                            )}
                        </div>
                    </div>
                    <Sidebar posts={posts} />
                </section>
            </div>
        </>
    );
};

export default App;

export const getStaticProps: GetStaticProps = async (): Promise<{
    props: { posts: PostEntity[] };
}> => ({
    props: {
        posts: getSortedPostsData()
    }
});
